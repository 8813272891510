import ChartHeaderValue from "./ChartHeaderValue";

export default function ChartHeader({ title, value, percent }) {
  return (
    <>
      <p className="title-sm text-greyTitle">{title}</p>
      <p className="page-block-value">
        {value}{" "}
        {percent && (
          <span className="ml-[4px]">
            <ChartHeaderValue percent={percent} />
          </span>
        )}
      </p>
    </>
  );
}
