import Layout from "../components/Layout";
import UserIdLayout from "../components/users/UserIdLayout";
import { UsersStatsItem } from "./UsersPage";
import { useState } from "react";
import { Select, MenuItem } from "@mui/material";
import PersonalDetails from "../components/users/PersonalDetails";
import Skills from "../components/users/Skills";
import SimilarPlayers from "../components/users/SimilarPlayers";
import DrillsHistoryTable from "../components/users/DrillsHistoryTable";
import { NavLink } from "react-router-dom";
import { HlightsItem } from "./HighlightsPage";
import UserJourneyTable from "../components/users/UserJourneyTable";
import ChatsList from "../components/users/ChatsList";
import { useSelector } from "react-redux";
import { instance } from "../routes/axios";
import { useEffect } from "react";

export default function UserIdPage() {
  const [userState, setUserState] = useState("unranked");
  const handleUserStateChange = (event) => setUserState(event.target.value);
  const user = useSelector((state) => state.user);
  const [chats, setChats] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await instance.post("/get-coach-chats", { userDetailsId: user?.userDetails?._id });
      setChats(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  // console.log(user);

  return (
    <Layout>
      <UserIdLayout>
        <div className="usersStats main-content-px grid-cols-4 py-15">
          <UsersStatsItem title="State *todo">
            <Select
              variant="standard"
              className="state-select"
              value={userState}
              onChange={handleUserStateChange}>
              <MenuItem value={"unranked"}>unranked</MenuItem>
              <MenuItem value={"amateur"}>amateur</MenuItem>
              <MenuItem value={"semi-pro"}>semi-pro</MenuItem>
              <MenuItem value={"professional"}>professional</MenuItem>
              <MenuItem value={"world-class"}>world-class</MenuItem>
            </Select>
          </UsersStatsItem>
          <UsersStatsItem
            title="Drills Completed"
            value={user?.userDetails?.drills?.completedCount}
          />
          <UsersStatsItem
            title="Rank"
            value={user?.userDetails?.rank}
          />
          <UsersStatsItem
            title="Avg. Score"
            value={user?.userDetails?.avgScore}
          />
        </div>
        <PersonalDetails rank={userState} />
        <div className="userIdGroup grid-cols-2">
          <Skills />
          <SimilarPlayers />
        </div>
        <div className="userIdGroup">
          <div className="main-content-px">
            <p className="title-sm text-greyTitle flex items-center justify-between">
              Drills History *todo{" "}
              <NavLink
                className="text-linkColor"
                to="/users/123/drill-history">
                See More
              </NavLink>
            </p>
          </div>
          <DrillsHistoryTable hidePagination />
        </div>

        <div className="userIdGroup grid-cols-2">
          <div className="userIdGroup__block border-right !pb-0">
            <div className="main-content-px">
              <p className="title-sm text-greyTitle flex items-center justify-between">
                Sessions *todo{" "}
                <NavLink
                  className="text-linkColor"
                  to="/users/123/sessions">
                  See More
                </NavLink>
              </p>
            </div>
            <UserJourneyTable hidePagination />
          </div>

          <div className="userIdGroup__block !pb-0">
            <div className="main-content-px">
              <p className="title-sm text-greyTitle flex items-center justify-between">
                Coach Chats *todo maybe show last 5-6 messages
                <NavLink
                  className="text-linkColor"
                  to="/users/123/coach-chats">
                  See More
                </NavLink>
              </p>
              <div className="userCoachChats">
                <ChatsList data={chats} />
              </div>
            </div>
          </div>
        </div>

        <div className="userIdGroup border-none">
          <div className="main-content-px">
            <p className="title-sm text-greyTitle flex items-center justify-between">
              Highlights *todo{" "}
              <NavLink
                className="text-linkColor"
                to="/users/123/Highlights">
                See More
              </NavLink>
            </p>
            <div className="hlights__list">
              <HlightsItem />
              <HlightsItem />
              <HlightsItem />
              <HlightsItem />
            </div>
          </div>
        </div>
      </UserIdLayout>
    </Layout>
  );
}
