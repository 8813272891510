import ChartHeader from "../charts/ChartHeader";
import DistributionChart from "../charts/DistributionChart";
export default function Distribution() {
  return (
    <div className="page-block page-block-pd">
      <ChartHeader title="Male / Female Distribution" />
      <DistributionChart />
    </div>
  );
}
