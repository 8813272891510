import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { Button } from "rsuite";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

export default function SettingsSecurity() {
  return (
    <div className="page-block page-block-pd settings__sec">
      <p className="title-sm text-greyTitle">Security</p>
      <div className="settings__sec-heading">
        <AdminPanelSettingsIcon />
        <p className="settings__sec-title">2FA Authentication</p>
      </div>
      <p className="settings__sec-text">Protect your account by adding an extra layer of security. A second login step can keep your account secure, even if your password is compromised. To enable it, all you need is a smart phone.</p>
      <div className="settings__sec-list">
        <div className="settings__sec-item">
          <QrCode2Icon />
          <div className="settings__sec-content">
            <p className="sec-title">
              Authenticator app <span className="settings__sec-status">Active</span>
            </p>
            <p>Last time updated : 12.09.2022</p>
          </div>
          <Button
            appearance="ghost"
            className="outlined-btn red-outlined-btn">
            Remove
          </Button>
        </div>
        <div className="settings__sec-item add">
          <AddCircleRoundedIcon />
          <div className="settings__sec-content">
            <p className="sec-title">Add additional authentication method</p>
            <p>SMS Delivery, Email verification and more...</p>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
