import ReactCountryFlag from "react-country-flag";

export default function CountryFlag({ country, disableMarginR }) {
  return (
    <ReactCountryFlag
      countryCode={country}
      svg
      style={{
        width: "20px",
        height: "auto",
        marginRight: disableMarginR ? 0 : "6px",
      }}
      title={country}
    />
  );
}
