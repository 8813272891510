import { Avatar } from "rsuite";
import { Button } from "rsuite";
import EditIcon from "@mui/icons-material/Edit";
import CopyButton from "../utils/CopyButton";
import { useNavigate } from "react-router-dom";

export default function SettingsProfile() {
  const navigate = useNavigate();

  return (
    <div className="page-block page-block-pd">
      <p className="title-sm text-greyTitle">Admin Details</p>
      <div className="settings__profile">
        <Avatar size="xl">PM</Avatar>
        <div className="settings__profile-content">
          <div>
            <p className="settings__profile-name">Pavels Martinovs</p>
            <p className="settings__profile-position">Master Admin</p>
          </div>
          <p className="settings__profile-email">
            Pavels.martinovs@gmail.com <CopyButton msg="Pavels.martinovs@gmail.com" />
          </p>
        </div>
      </div>
      <Button
        onClick={() => navigate("/settings/edit-profile")}
        appearance="primary"
        className="black-btn outlined-btn mt-18"
        startIcon={<EditIcon />}>
        {" "}
        Edit Profile
      </Button>
    </div>
  );
}
