import { useRef, useCallback } from "react";

const useDebounce = (func, delay) => {
  const inDebounce = useRef();

  const debounce = useCallback(
    function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce.current);
      inDebounce.current = setTimeout(() => func.apply(context, args), delay);
    },
    [func, delay]
  );

  return debounce;
};

export default useDebounce;
