import AddIcon from "@mui/icons-material/Add";
import CustomDialog from "../libs/CustomDialog";
import { useState } from "react";
import AddNoteForm from "./AddNoteForm";
import { instance } from "../../routes/axios";
import { useDispatch, useSelector } from "react-redux";
import CustomDialogIcon from "../libs/CustomDialogIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { setUserData } from "../../store/userSlice";
import { showSnackbar } from "../../store/snackbarSlice";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";

export default function PersonalDetailsNotes() {
  const [dialog, setDialog] = useState(false);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const user = useSelector((state) => state.user);
  const { userDetails } = user || [];
  const { id } = useParams();
  const dispatch = useDispatch();

  const deleteContent = {
    title: "Delete note",
    icon: <DeleteIcon />,
    text: "Are you sure you want to delete the note?",
    buttonText: "Delete",
    buttonAction: deleteNote,
  };

  function openDeleteDialog(x) {
    setSelectedNote(x);
    setDialogDelete(true);
  }

  async function deleteNote() {
    setLoading(true);
    try {
      const res = await instance.post("/delete-note", { userDetailsId: userDetails?._id, notesId: selectedNote._id });
      dispatch(setUserData(null));
      dispatch(showSnackbar({ message: "Note has been successfuly deleted", severity: "success" }));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="userIdGroup__block main-content-px">
        <p className="title-sm text-greyTitle flex items-center justify-between">
          Notes
          <button onClick={() => setDialog(true)}>
            <AddIcon />
          </button>
        </p>
        {userDetails?.notes.length < 1 ? (
          <div className="text-greyTitle text-center">No data available</div>
        ) : (
          <div className="notes">
            {userDetails?.notes.map((x) => {
              return (
                <div
                  key={x._id}
                  className="notesItem">
                  <div>
                    <p className="notesItem__date">{moment(x.createdDate).format("LLL")}</p>
                    <p className="notesItem__content">{x.text}</p>
                  </div>
                  <button onClick={() => openDeleteDialog(x)}>
                    <ClearIcon />
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <CustomDialogIcon
        open={dialogDelete}
        close={() => setDialogDelete(false)}
        content={deleteContent}
        isLoading={isLoading}>
        <p className="notesItem__text">[{selectedNote?.text}]</p>
      </CustomDialogIcon>
      <CustomDialog
        open={dialog}
        close={() => setDialog(false)}
        title="Add note">
        <AddNoteForm close={() => setDialog(false)} />
      </CustomDialog>
    </>
  );
}
