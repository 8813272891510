import ChartHeader from "../charts/ChartHeader";
import CallMadeIcon from "@mui/icons-material/CallMade";
import DrillsChart from "../charts/DrillsChart";

export function DrillItem({ item }) {
  return (
    <div className="drills__item">
      <div>
        <div className="drills__title">
          <div
            className="drills__title-color"
            style={{ background: `${item.color}` }}></div>
          <p className="title-sm text-greyTitle">{item.type}</p>
        </div>
        <div className="page-block-value flex items-center mb-0">
          {item.value}
          <div className="drills__icon">
            <CallMadeIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function TotalDrills() {
  const totalData = [
    { type: "Completed", value: "18,477", color: "#0fca7a" },
    { type: "In Review", value: "60,775", color: "#3BCEF7" },
    { type: "Failed", value: "42,566", color: "#F7A23B" },
    { type: "Rejected", value: "66,095", color: "#F75D5F" },
  ];

  return (
    <div className="page-block page-block-pd">
      <ChartHeader
        title="Total Drills"
        value={"72,095"}
        percent={"+1.01%"}
      />
      <div className="drills">
        {totalData.map((x) => {
          return (
            <DrillItem
              key={x.type}
              item={x}
            />
          );
        })}
      </div>
      <DrillsChart totalData={totalData} />
    </div>
  );
}
