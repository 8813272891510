import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, Collapse, Pagination } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VideocamIcon from "@mui/icons-material/Videocam";
import { drillsData } from "./testData";
import { tableCellClasses } from "@mui/material/TableCell";

function DrillScoreItem() {
  return (
    <div className="drillExpand__item">
      <p className="text-greyTitle flex items-center justify-between">
        Agility <span className="skills__status very-good">Very good</span>
      </p>
      <div className="flex items-center justify-between">
        <p className="drillExpand__item-text font-medium">74%</p>
        <div className="flex items-center">
          <span className="skills__status mr">5 of 5</span>
          <VideocamIcon />
        </div>
      </div>
    </div>
  );
}
function DrillHighlightItem() {
  return (
    <div className="">
      <img
        className="drillExpand__video"
        src="/images/avatar.png"
        alt=""
      />
      <div className="flex items-center justify-between">
        <p className="skills__item-text font-medium">13:57</p>
        <span className="skills__status bad">Bad</span>
      </div>
    </div>
  );
}

function RenderRowExpanded({ row }) {
  return (
    <div className="drillExpand">
      <div className="drillExpand__block main-content-px">
        <div>
          <p className="drillExpand__block-value">65%</p>
          <p className="drillExpand__block-small">Total Drill Score</p>
        </div>
        <div className="drillExpand__block-list">
          <DrillScoreItem />
          <DrillScoreItem />
          <DrillScoreItem />
          <DrillScoreItem />
          <DrillScoreItem />
        </div>
      </div>
      <div className="drillExpand__block main-content-px">
        <div>
          <p className="drillExpand__block-value">7</p>
          <p className="drillExpand__block-small">Highlights</p>
        </div>
        <div className="drillExpand__block-list">
          <DrillHighlightItem />
          <DrillHighlightItem />
          <DrillHighlightItem />
          <DrillHighlightItem />
          <DrillHighlightItem />
          <DrillHighlightItem />
          <DrillHighlightItem />
          <DrillHighlightItem />
        </div>
      </div>
      <div className="drillExpand__block main-content-px">
        <div>
          <p className="drillExpand__block-value">3</p>
          <p className="drillExpand__block-small">Drill Attempts</p>
        </div>
        <div className="drillExpand__block-list">
          <DrillScoreItem />
          <DrillScoreItem />
          <DrillScoreItem />
        </div>
      </div>
    </div>
  );
}

const tableHead = ["Name", "Results", "Attempts", "Date", ""];

export default function DrillsHistoryTable({ hidePagination }) {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(!hidePagination ? 10 : 5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExpandClick = (id) => {
    setExpandedRowKeys((prev) => (prev.includes(id) ? prev.filter((key) => key !== id) : [...prev, id]));
  };

  return (
    <>
      <TableContainer>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}>
          <TableHead>
            <TableRow>
              {tableHead.map((x) => {
                return <TableCell key={x}>{x}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {drillsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <React.Fragment key={row.id}>
                <TableRow
                  hover
                  onClick={() => handleExpandClick(row.id)}
                  style={{ cursor: "pointer" }}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.result}</TableCell>
                  <TableCell>{row.attempts}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>
                    <div className="flex items-center justify-end">{expandedRowKeys.includes(row.id) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>
                  </TableCell>
                </TableRow>
                <TableRow className="expand">
                  <TableCell colSpan={5}>
                    <Collapse
                      in={expandedRowKeys.includes(row.id)}
                      timeout="auto"
                      unmountOnExit>
                      <RenderRowExpanded row={row} />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!hidePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={drillsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}
