export default function AuthFormGroup({ label, children, error }) {
  return (
    <div className="auth__form-group">
      {label && <p className="auth__form-label">{label}</p>}
      {children}
      {error && error.type === "required" && <p className="error-text mt-8">* The field is required</p>}
      {error && error.type === "validate" && <p className="error-text mt-8">{error.message}</p>}
    </div>
  );
}
