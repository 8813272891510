import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";

export default function CopyButton({ msg }) {
  const [text, setText] = useState("Copy");

  const handleCopy = () => {
    navigator.clipboard.writeText(msg).then(() => {
      setText("Copied!");
    });
    setTimeout(() => {
      setText("Copy");
    }, 1000);
  };

  return (
    <Tooltip
      placement="top"
      title={text}>
      <span
        onClick={handleCopy}
        className="copy-icon">
        <ContentCopyIcon />
      </span>
    </Tooltip>
  );
}
