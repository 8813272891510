import ChartHeader from "../charts/ChartHeader";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CoachChart from "../charts/CoachChart";
import { DrillItem } from "./TotalDrills";

export default function Coach() {
  const totalData = [
    { type: "Total Send", value: "959,192", color: "#0fca7a" },
    { type: "Reply", value: "24%", color: "#3BCEF7" },
    { type: "Errors", value: "380", color: "#F75D5F" },
  ];

  return (
    <div className="page-block page-block-pd">
      <ChartHeader title="Coach Interactions" />
      <div className="drills coach">
        {totalData.map((x) => {
          return (
            <DrillItem
              key={x.type}
              item={x}
            />
          );
        })}
      </div>
      <CoachChart totalData={totalData} />
    </div>
  );
}
