import ChartHeader from "../charts/ChartHeader";
import ChartHeaderValue from "../charts/ChartHeaderValue";

export default function SignUpStatus() {
  const data = [
    {
      label: "Active",
      sessions: "712",
      value: "55%",
      percent: "+1.01%",
    },
    {
      label: "Blocked",
      sessions: "712",
      value: "12%",
      percent: "+1.01%",
    },
    {
      label: "Rejected",
      sessions: "712",
      value: "12%",
      percent: "+1.01%",
    },
    {
      label: "Onboarding",
      sessions: "712",
      value: "12%",
      percent: "+1.01%",
    },
  ];

  function Item({ item }) {
    return (
      <div className="signUpStatus__item page-block-pd">
        <div>
          <p>{item.label}</p>
          <p className="text-[#667085]">{item.sessions} session(s)</p>
        </div>
        <p className="text-right">{item.value}</p>
        <div className="text-right">
          <ChartHeaderValue percent={item.percent} />
        </div>
      </div>
    );
  }

  return (
    <div className="page-block pb-0">
      <div className="page-block-pd">
        <ChartHeader title="Sign Up Status" />
      </div>
      <div className="signUpStatus">
        {data.map((x) => {
          return (
            <Item
              key={x.label}
              item={x}
            />
          );
        })}
      </div>
    </div>
  );
}
