import React, { useEffect, useState, useMemo } from "react";
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, Collapse, Pagination } from "@mui/material";
import { drillsData } from "./testData";
import { tableCellClasses } from "@mui/material/TableCell";
import { Tag } from "rsuite";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";

const tableHead = ["Id", "Date", "User Journey"];

export default function UserJourneyTable({ hidePagination }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(!hidePagination ? 10 : 5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const data = useMemo(
    () => [
      {
        id: 1,
        date: "18.08.2024, 15:36",
        journey: ["Log in", "Drill's page", "Drill's page", "Drill's page", "Example one", "Log in", "Drill's page", "Drill's page", "Drill's page", "Example one", "Log in", "Drill's page", "Drill's page", "Drill's page", "Example one", "Log in", "Drill's page", "Drill's page", "Drill's page", "Example one"],
      },
      {
        id: 2,
        date: "18.08.2024, 15:36",
        journey: ["Log in", "Drill's page Drill's page Drill's page", "Drill's page", "Log in", "Drill's page Drill's page Drill's page", "Drill's page", "Log in", "Drill's page Drill's page Drill's page", "Drill's page", "Log in", "Drill's page Drill's page Drill's page", "Drill's page"],
      },
      {
        id: 3,
        date: "18.08.2024, 15:36",
        journey: ["Log in", "Drill's page Drill's page Drill's page", "Drill's page Drill's page Drill's page", "Log in", "Drill's page Drill's page Drill's page", "Drill's page Drill's page Drill's page", "Log in", "Drill's page Drill's page Drill's page", "Drill's page Drill's page Drill's page"],
      },
      {
        id: 4,
        date: "18.08.2024, 15:36",
        journey: ["Log in", "Drill's page Drill's page Drill's page", "Drill's page Drill's page Drill's page", "Drill's page Drill's page Drill's page", "Drill's page Drill's page Drill's page"],
      },
      {
        id: 5,
        date: "18.08.2024, 15:36",
        journey: ["Log in", "Drill's page"],
      },
    ],
    []
  );

  function expandTagsList(e) {
    const isOneLine = e.currentTarget.querySelector(".journeyCell__block").classList.contains("one-line");
    if (isOneLine) return;
    e.currentTarget.classList.toggle("expanded");
  }

  useEffect(() => {
    const handleResize = () => {
      const blocks = document.querySelectorAll(".journeyCell__block");
      blocks.forEach((item, index) => {
        const availableWidth = item.querySelector(".journeyCell__left").getBoundingClientRect().width;
        const list = item.querySelector(".journeyCell__left-list");
        const listWidth = list.getBoundingClientRect().width;
        const moreBtn = item.querySelector(".journeyCell__more");
        const isOverflow = listWidth > availableWidth;
        if (!isOverflow) {
          item.classList.add("one-line");
          moreBtn.classList.add("hidden");
        } else {
          item.classList.remove("one-line");
          moreBtn.classList.remove("hidden");
        }
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data]);

  return (
    <>
      <TableContainer>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}>
          <TableHead>
            <TableRow>
              {tableHead.map((x, index) => {
                if (hidePagination && index === 0) {
                  return null;
                }
                return <TableCell key={x}>{x}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <React.Fragment key={row.id}>
                <TableRow
                  hover
                  className="cursor-pointer sessionsTableRow"
                  onClick={expandTagsList}>
                  {!hidePagination && <TableCell>{row.id}</TableCell>}
                  <TableCell>{row.date}</TableCell>
                  <TableCell className="journeyCell">
                    <div className="journeyCell__block">
                      <div className="journeyCell__left">
                        <div className="journeyCell__more">
                          <MoreHorizIcon />
                        </div>
                        <div className="journeyCell__left-list">
                          {row.journey.slice(0, -1).map((x, index) => {
                            return (
                              <div
                                className="journeyCell__item"
                                key={x + index}>
                                <Tag
                                  size="lg"
                                  key={x}>
                                  {x}
                                </Tag>
                                <ArrowForwardIcon />
                              </div>
                            );
                          })}
                          <Tag size="lg">{row.journey[row.journey.length - 1]}</Tag>
                        </div>
                      </div>
                      <div className="journeyCell__item">
                        <Tag
                          size="lg"
                          className="journeyCell__item-last">
                          {row.journey[row.journey.length - 1]}
                        </Tag>
                        <CloseIcon className="journeyCell__item-close" />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!hidePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={drillsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}
