import ChartHeader from "../charts/ChartHeader";
import TotalUsersChart from "../charts/TotalUsersChart";

export default function TotalUsers() {
  return (
    <div className="page-block page-block-pd">
      <ChartHeader
        title="Total Users"
        value={"6,092"}
        percent={"+1.01%"}
      />
      <TotalUsersChart />
    </div>
  );
}
