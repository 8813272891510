import CountryFlag from "../libs/CountryFlag";
import "../../assets/styles/userRank.scss";
import LockIcon from "@mui/icons-material/Lock";

export default function PersonalDetailsRank({ rank }) {
  return (
    <div className="userIdGroup__block border-right main-content-px">
      <p className="title-sm text-greyTitle">Rank *todo</p>
      <div className={`userRank ${rank}`}>
        <img
          src={`/images/rank/${rank}.png`}
          alt="rank"
          loading="lazy"
        />
        <div className="userRank__container">
          <p className="userRank__title">{rank}</p>
          <div className="grid grid-cold-1fr-auto items-center">
            <div className="userRank__st">
              <p>100</p>
              <p>st</p>
              <div className="line"></div>
              <CountryFlag
                disableMarginR={true}
                country="US"
              />
            </div>
            <div className="userRank__avatar">
              <img
                src="/images/avatar.png"
                alt=""
              />
            </div>
          </div>
          <div className="userRank__stats">
            <p className="userRank__stats-title">Sadio</p>
            <div className="line userRank__stats-line"></div>
            <div className="userRank__stats-list">
              <div className="userRank__stats-block">
                <p>88% MOV</p>
                <p>56% CTRL</p>
                <p>9% TECH</p>
              </div>
              <div className="line"></div>
              <div className="userRank__stats-block">
                <p>
                  <LockIcon /> SPD
                </p>
                <p>32% AWA</p>
                <p>11% DIR</p>
              </div>
            </div>
            <div className="line userRank__link"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
