import { TagPicker } from "rsuite";

export default function MultipleAutocomplete({ label, options, onChange }) {
  return (
    <div>
      <p className="filter-label">{label}</p>
      <TagPicker
        data={options}
        style={{ width: 224 }}
        countable={false}
        size="lg"
      />
    </div>
  );
}
