import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");

  if (typeof window === "undefined") {
    return "";
  }

  return !token && !token?.length > 0 ? <Navigate to="/" /> : <Component {...rest} />;
  // return <Component {...rest} />;
};

export default ProtectedRoute;
