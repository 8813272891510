import { Link, useNavigate } from "react-router-dom";
import AuthBackground from "../components/auth/AuthBackground";
import AuthHeading from "../components/auth/AuthHeading";
import { InputAdornment, IconButton, Input } from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AuthFormGroup from "../components/auth/AuthFormGroup";
import { useForm } from "react-hook-form";
import AuthCode from "react-auth-code-input";
import { Button } from "rsuite";
import axios from "axios";
import { instance } from "../routes/axios";

export default function LoginPage() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTwoFa, setIsTwoFa] = useState(false);
  const [twoFaCode, setTwoFaCode] = useState("");
  const [data, setData] = useState("");
  const [resErr, setResErr] = useState("");

  console.log(twoFaCode);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  async function onSubmit(data) {
    setData(data);
    setIsLoading(true);
    // setIsTwoFa(true);
    console.log(data);
    try {
      const res = await instance.post("/admin-login", { email: data.email, password: data.password });
      if (res) {
        localStorage.setItem("token", res.data.token);
        reset();
        // navigate("/dashboard");
        window.location.href = "/dashboard";
      }
    } catch (err) {
      setResErr(err?.response.data.message);
      console.log(err);
      setTimeout(() => {
        setResErr("");
      }, 2000);
    } finally {
      setIsLoading(false);
    }
  }

  function login() {}

  function backToLoginForm() {
    setIsTwoFa(false);
    setTwoFaCode("");
  }

  return (
    <div className="auth">
      <AuthBackground />
      {!isTwoFa && (
        <div className="auth__block">
          <AuthHeading
            title="Log in"
            subtitle="Welcome back! Please enter your details."
          />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="auth__form">
            <AuthFormGroup
              label="Email"
              error={errors.email}>
              <Input
                {...register("email", { required: true })}
                fullWidth
                placeholder="example@email.com"
                autoComplete="current-email"
              />
            </AuthFormGroup>
            <AuthFormGroup
              label="Password"
              error={errors.password}>
              <Input
                fullWidth
                {...register("password", { required: true })}
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                placeholder="Enter password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prev) => !prev)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </AuthFormGroup>
            {resErr && <p className="error-text">{resErr}</p>}
            <div className="flex justify-end">
              <Link
                className="auth__link"
                to="/forgot-password">
                Forgot password
              </Link>
            </div>
            <div className="auth__submit-block">
              <Button
                className="auth__submit"
                type="submit"
                loading={isLoading}>
                Log In
              </Button>
            </div>
          </form>
        </div>
      )}

      {isTwoFa && (
        <div className="auth__block">
          <AuthHeading
            title="Two-Factor <br/>Authentification"
            subtitle="Please open your authenticator app and enter the verification code below."
          />
          <div className="auth__form">
            <div className="authCode">
              <AuthCode
                containerClassName="authCode-container"
                onChange={(code) => {
                  // setError('');
                  setTwoFaCode(code);
                }}
              />
            </div>
            {/* <p className="error-text">Server error text</p> */}
            <div className="auth__submit-block">
              <Button
                loading={isLoading}
                onClick={login}
                className="auth__submit"
                color="black"
                disabled={twoFaCode.length < 6}>
                Log In
              </Button>
            </div>
            <div className="text-center">
              <span className="auth__link-text">Back to</span>{" "}
              <span
                className="auth__link"
                onClick={backToLoginForm}>
                Login
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
