import { Menu, NotificationsNone } from "@mui/icons-material";

export default function Header({ handleDrawer }) {
  return (
    <header className="header">
      <div className="header__left">
        <button
          className="header__menuBtn"
          onClick={() => handleDrawer((prev) => !prev)}>
          <Menu />
        </button>
        <img
          src="/images/logo-white.svg"
          alt="logo"
        />
      </div>
      <div className="header__right">
        <p className="header__search">search</p>
        <NotificationsNone />
        <div className="header__user">
          <div className="header__user-icon">PM</div>
        </div>
      </div>
    </header>
  );
}
