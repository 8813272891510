import ChartHeader from "../charts/ChartHeader";
import PlayerRoleChart from "../charts/PlayerRoleChart";

export default function PlayerRole() {
  return (
    <div className="page-block page-block-pd">
      <ChartHeader title="Player Role Distribution" />
      <PlayerRoleChart />
    </div>
  );
}
