import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const token = localStorage.getItem("token");

  if (typeof window === "undefined") {
    return null;
  }
  // If restricted is false in any public route, then that page will be accessible to the authenticated user.
  return token && restricted ? <Navigate to="/dashboard" /> : <Component {...rest} />;
};

export default PublicRoute;
