import Layout from "../components/Layout";
import SettingsDevices from "../components/settings/SettingsDevices";
import SettingsLogs from "../components/settings/SettingsLogs";
import SettingsPassword from "../components/settings/SettingsPassword";
import SettingsProfile from "../components/settings/SettingsProfile";
import SettingsSecurity from "../components/settings/SettingsSecurity";

export default function SettingsPage() {
  return (
    <Layout bodyColor="#eef2f6">
      <div className="settings main-content-px">
        <SettingsProfile />
        <SettingsPassword />
        <SettingsSecurity />
        <SettingsLogs />
        <SettingsDevices />
      </div>
    </Layout>
  );
}
