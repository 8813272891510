import { useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function PlayerRoleChart() {
  const [chartData] = useState({
    options: {
      chart: { fontFamily: "Inter, sans-serif" },
      labels: ["Goalkeeper", "Striker", "Defender"],
      dataLabels: {
        style: {
          colors: ["black"],
          textShadow: false,
        },
        dropShadow: false,
      },
      tooltip: {
        theme: "dark",
      },
    },

    series: [44, 55, 17],
  });

  return (
    <div className="player-role-chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="donut"
        height={227}
      />
    </div>
  );
}
