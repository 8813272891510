import { LeaderItem } from "../dashboard/Leaderboard";

export default function SimilarPlayers() {
  return (
    <div className="userIdGroup__block">
      <div className="main-content-px">
        <p className=" title-sm text-greyTitle">Similar players *todo</p>
      </div>
      <div className="leaderList similarPlayersList">
        <LeaderItem />
        <LeaderItem />
        <LeaderItem />
        <LeaderItem />
        <LeaderItem />
        <LeaderItem />
      </div>
    </div>
  );
}
