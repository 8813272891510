import { Avatar } from "rsuite";
import moment from "moment";

export default function ChatsList({ data, searchValue }) {
  const highlightText = (text) => {
    if (!searchValue) return text;
    const regex = new RegExp(`(${searchValue})`, "gi");
    return text.replace(regex, '<span class="highlight">$1</span>');
  };
  return (
    <div className="chats__list">
      {data.length > 0 ? (
        data.map((x) => {
          return (
            <div
              key={x._id}
              className={`chats__item ${!x.isCoach ? "user-style" : ""}`}>
              <Avatar
                size="md"
                circle
              />
              <div>
                <div className="chats__item-header">
                  <p>{x.name}</p>
                  <p>{moment(x.date).format("DD/MM/YYYY HH:mm")}</p>
                </div>
                <p
                  className="chats__item-message"
                  dangerouslySetInnerHTML={{ __html: highlightText(x.message) }}
                />
              </div>
            </div>
          );
        })
      ) : (
        <p>No data found</p>
      )}
    </div>
  );
}
