import Dialog from "@mui/material/Dialog";
import { Button } from "rsuite";

export default function CustomDialogIcon({ open, close, content, isLoading, children }) {
  return (
    <Dialog
      onClose={close}
      open={open}
      fullWidth
      maxWidth="sm"
      className="c-dialog c-dialogIcon">
      {content && (
        <div className={`c-dialog__content ${content.style ? content.style : ""}`}>
          <div className="c-dialogIcon__content">
            <div className="c-dialogIcon__icon">{content.icon}</div>
            <div>
              <p className="c-dialogIcon__title">{content.title}</p>
              <p className="c-dialog__text">{content.text}</p>
              {children}
            </div>
          </div>
          <div className="c-dialog__btns">
            <Button
              appearance="ghost"
              onClick={close}
              className="outlined-btn">
              Cancel
            </Button>
            <Button
              appearance="primary"
              loading={isLoading}
              onClick={content.buttonAction}
              className={`${content.style === "success" ? "green-btn" : "red-btn"} outlined-btn min-width`}>
              {content.buttonText}
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
}
