export default function Skills() {
  function SkillsItem({ title, status, value }) {
    return (
      <div className="skills__item">
        <p className="skills__item-block">
          {title} <span className={`skills__status ml ${status}`}>{status}</span>
        </p>
        <p className="skills__item-value">{value}</p>
      </div>
    );
  }

  return (
    <div className="userIdGroup__block border-right main-content-px">
      <p className="title-sm text-greyTitle">Skills *todo</p>
      <div className="skills__list">
        <SkillsItem
          title="Movement"
          value={88}
          status={"very-good"}
        />
        <SkillsItem
          title="Speed"
          value={88}
          status={"average"}
        />
        <SkillsItem
          title="Awareness"
          value={88}
          status={"bad"}
        />
        <SkillsItem
          title="Ball control"
          value={88}
          status={"bad"}
        />
        <SkillsItem
          title="Technique"
          value={88}
          status={"very-good"}
        />
        <SkillsItem
          title="Change direction"
          value={88}
          status={"very-good"}
        />
      </div>
    </div>
  );
}
