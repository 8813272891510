import UserIdLayout from "../components/users/UserIdLayout";
import Layout from "../components/Layout";
import { UsersStatsItem } from "./UsersPage";
import UserJourneyTable from "../components/users/UserJourneyTable";
import { useSelector } from "react-redux";

export default function SessionsPage() {
  const user = useSelector((state) => state.user);

  return (
    <Layout>
      <UserIdLayout>
        <div className="usersStats main-content-px grid-cols-4 py-15">
          <UsersStatsItem
            title="Onboarding *todo"
            value="Completed"
          />
          <UsersStatsItem
            title="Avg. time spent"
            value={user?.userDetails?.sessions?.avgTimeSpent}
          />
          <UsersStatsItem
            title="Avg. page count"
            value={user?.userDetails?.sessions?.avgPageCount}
          />
          <UsersStatsItem
            title="Use over time"
            value={user?.userDetails?.sessions?.useOverTime}
          />
        </div>
        <UserJourneyTable />
      </UserIdLayout>
    </Layout>
  );
}
