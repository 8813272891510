import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import { Button } from "rsuite";

export default function SettingsDevices() {
  return (
    <div className="page-block page-block-pd settings__act">
      <p className="title-sm text-greyTitle">Active Devices</p>
      <div className="settings__sec-heading">
        <DevicesRoundedIcon />
        <p className="settings__sec-title">Where you’ve logged in</p>
      </div>
      <div className="settings__sec-list">
        <div className="settings__sec-item">
          <div className="settings__sec-content">
            <p className="sec-title">
              2018 Macbook Pro 15-inch <span className="settings__sec-status active">Active</span>
            </p>
            <p>Melbourne, Australia • 22 Jan at 10:40am</p>
          </div>
          <Button
            appearance="ghost"
            className="outlined-btn red-outlined-btn">
            Logout
          </Button>
        </div>
        <div className="settings__sec-item">
          <div className="settings__sec-content">
            <p className="sec-title">
              2018 Macbook Pro 15-inch <span className="settings__sec-status inactive">Inactive</span>
            </p>
            <p>Melbourne, Australia • 22 Jan at 10:40am</p>
          </div>
          <Button
            appearance="ghost"
            className="outlined-btn red-outlined-btn">
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
}
