import { useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function DistributionChart() {
  const [chartData] = useState({
    options: {
      chart: { fontFamily: "Inter, sans-serif" },
      xaxis: {
        categories: ["Male", "Female"],
      },
      colors: ["#0091FF"],
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Distribution",
        data: [6000, 4000],
      },
    ],
  });

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="bar"
      height={227}
    />
  );
}
