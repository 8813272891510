import { useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function LocationChart() {
  const [chartData] = useState({
    options: {
      chart: { fontFamily: "Inter, sans-serif" },
      plotOptions: {
        bar: {
          borderRadiusApplication: "end",
          horizontal: true,
        },
      },
      xaxis: {
        categories: ["United States", "Canada", "United Kingdom", "Netherlands", "Italy", "France", "Japan", "Africa", "China", "Germany"],
      },
      colors: ["#0091FF"],
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Accounts",
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
      },
    ],
  });

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="bar"
      height={227}
    />
  );
}
