// import { Form, Input } from "antd";
// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import apiRequest from "../utils/api";
// import { useNavigate, useParams } from "react-router-dom";
// import FormLogo from "../components/forms/FormLogo";
// import FormMessage from "../components/forms/FormMessage";
// import ThemeButton from "../components/common/ThemeButton";

import AuthBackground from "../components/auth/AuthBackground";
import AuthHeading from "../components/auth/AuthHeading";
import { useForm } from "react-hook-form";
import { useState } from "react";
import AuthFormGroup from "../components/auth/AuthFormGroup";
import { InputAdornment, IconButton, Input } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AuthSuccess from "../components/auth/AuthSuccess";
import { Button } from "rsuite";

export default function ResetPasswordPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function onSubmit(data) {
    console.log(data);
    setIsSuccess(true);
    reset();
  }

  // const [step, setStep] = useState(1);
  // const [loading, setLoading] = useState(false);
  // const { token } = useParams();
  // const [userEmail, setuserEmail] = useState("");

  // const navigate = useNavigate();

  // const updatePassword = async (values) => {
  //   const body = {
  //     verifyToken: token,
  //     email: userEmail,
  //     newPassword: values.confirmPassword,
  //   };
  //   setLoading(true);
  //   try {
  //     await apiRequest("/admin-new-password", "POST", body);
  //     setStep(2);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error posting data:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (token) {
  //     verifyToken(token);
  //   }
  // }, [token]);

  // const verifyToken = async (token) => {
  //   const body = {
  //     verifyToken: token,
  //   };
  //   try {
  //     const response = await apiRequest("/admin-forgot-password", "POST", body);
  //     if (response.data.success) {
  //       setuserEmail(response.data.email);
  //     } else {
  //       navigate("/");
  //     }
  //   } catch (error) {
  //     navigate("/");
  //     console.error("Error posting data:", error);
  //   }
  // };

  return (
    <div className="auth">
      <AuthBackground />
      <div className={`auth__block ${isSuccess ? "!grid-rows-none" : ""}`}>
        {!isSuccess && (
          <>
            <AuthHeading title="Enter <br/>New Password" />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="auth__form">
              <AuthFormGroup
                label="Password"
                error={errors.password}>
                <Input
                  fullWidth
                  {...register("password", { required: true })}
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((prev) => !prev)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </AuthFormGroup>
              <AuthFormGroup
                label="Confirm Password"
                error={errors.repeatPassword}>
                <Input
                  fullWidth
                  {...register("repeatPassword", { required: true })}
                  type="password"
                  placeholder="Enter password"
                />
              </AuthFormGroup>
              <div className="auth__submit-block">
                <Button
                  loading={isLoading}
                  type="submit"
                  className="auth__submit">
                  Reset Password
                </Button>
              </div>
            </form>
          </>
        )}
        {isSuccess && <AuthSuccess title="Password Updated">Password for email@gmail.com has been updated, return and log in to AIM.</AuthSuccess>}
      </div>
    </div>
  );
}
