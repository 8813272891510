import SuccessIcon from "../icons/SuccessIcon";
import { Link } from "react-router-dom";
import { Button } from "rsuite";

export default function AuthSuccess({ title, children, close, settingsButton }) {
  return (
    <div className="auth__form">
      <div className="flex justify-center">
        <SuccessIcon />
      </div>
      <div>
        <p className="text-center auth__title">{title}</p>
        <p className="text-center auth__subtitle">{children}</p>
      </div>
      <div className="auth__submit-block form__success_btn">
        {settingsButton && (
          <Button
            appearance="ghost"
            onClick={close}
            className="outlined-btn black-btn">
            Continue
          </Button>
        )}
        {!settingsButton && (
          <Link
            className="auth__submit"
            to="/">
            Continue
          </Link>
        )}
      </div>
    </div>
  );
}
