import ChartHeader from "../charts/ChartHeader";
import LocationChart from "../charts/LocationChart";
export default function Location() {
  return (
    <div className="page-block page-block-pd">
      <ChartHeader title="Accounts by Location" />
      <LocationChart />
    </div>
  );
}
