function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export const usersTableHead = [
  { id: "name", numeric: false, label: "Full Name" },
  { id: "userId", numeric: false, label: "User Id" },
  { id: "userDetails", numeric: false, label: "User details" },
  { id: "createdDate", numeric: true, label: "Created date" },
  { id: "updatedDate", numeric: false, label: "Updated date" },
];

export const adminsTableHead = [
  { id: "firstName", numeric: false, label: "Full Name" },
  { id: "email", numeric: false, label: "Email" },
  { id: "role", numeric: false, label: "Role" },
  { id: "createdDate", numeric: true, label: "Created date" },
  { id: "updatedDate", numeric: false, label: "Updated date" },
];
