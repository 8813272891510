import CountryFlag from "../libs/CountryFlag";
import { Button } from "rsuite";
import CustomDialogIcon from "../libs/CustomDialogIcon";
import { useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import DeleteIcon from "@mui/icons-material/Delete";
import { instance } from "../../routes/axios";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../store/snackbarSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { setUserData } from "../../store/userSlice";
import PersonIcon from "@mui/icons-material/Person";

export default function UserHeader() {
  const [dialog, setDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const dialogContents = {
    resetPassword: {
      title: "Reset password",
      icon: <RestartAltIcon />,
      text: "Are you sure you want to reset this account’s password?",
      buttonText: "Reset",
      buttonAction: resetPassword,
    },
    resetState: {
      title: "Reset state",
      icon: <RestartAltIcon />,
      text: "Are you sure you want to reset state of this account?",
      buttonText: "Reset",
      buttonAction: resetState,
    },
    deactivate: {
      title: "Deactivate account",
      icon: <PersonOffIcon />,
      text: "Are you sure you want to deactivate this account?",
      buttonText: "Deactivate",
      buttonAction: deactivate,
    },
    activate: {
      title: "Activate account",
      icon: <PersonIcon />,
      text: "Are you sure you want to activate this account?",
      buttonText: "Activate",
      buttonAction: deactivate,
      style: "success",
    },
    delete: {
      title: "Delete account",
      icon: <DeleteIcon />,
      text: "Are you sure you want to delete this account? This will permanently delete all of its data. You will not be able to reactivate it.",
      buttonText: "Delete",
      buttonAction: deleteUsers,
    },
  };

  function openDialog(content) {
    setDialogContent(content);
    setDialog(true);
  }

  async function resetPassword() {
    try {
      const res = await instance.post("/reset-password", { userId: user.userId });
      setLoading(false);
      setDialog(false);
      dispatch(showSnackbar({ message: "Password has been successfuly reseted", severity: "success" }));
    } catch (err) {
      console.log(err);
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }
  async function resetState() {
    try {
      const res = await instance.post("/reset-state", { userDetailsId: user?.userDetails?._id });
      setLoading(false);
      setDialog(false);
      dispatch(showSnackbar({ message: "State has been successfuly reseted", severity: "success" }));
    } catch (err) {
      console.log(err);
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }
  async function deactivate() {
    try {
      setLoading(true);
      const res = instance.post("/deactivate-user", { userId: user?.userId, deactivated: !user?.deactivated });
      console.log(res);
      setDialog(false);
      dispatch(setUserData(null));
      dispatch(showSnackbar({ message: `User has been ${user?.deactivated ? "activated" : "deactivated"}`, severity: "success" }));
    } catch (err) {
      console.log(err);
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  async function deleteUsers() {
    try {
      setLoading(true);
      const res = instance.post("/delete-app-user", { userIds: [user?.userId] });
      console.log(res);
      setDialog(false);
      navigate("/users");
      dispatch(showSnackbar({ message: `User has been deleted`, severity: "success" }));
    } catch (err) {
      console.log(err);
      dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="userHeader main-content-px">
      <div className="userHeader__left">
        <img
          className="userHeader__avatar"
          src="/images/avatar.png"
          // src={user?.profilePicture}
          alt=""
        />
        <div>
          <div className="userHeader__name">
            {user?.name}
            {user?.deactivated && (
              <p>
                <PersonOffIcon /> Deactivated
              </p>
            )}
          </div>
          <div className="userHeader__state">
            <p className="user-state">Onboarded</p>
            <div className="divider"></div>
            <div className="userHeader__country">
              <CountryFlag country="US" />
              Africa, Cape Town
            </div>
          </div>
        </div>
      </div>
      <div className="userHeader__right">
        <Button
          appearance="ghost"
          loading={isLoading}
          onClick={() => openDialog("resetPassword")}
          className="outlined-btn">
          Reset Pass
        </Button>
        <Button
          appearance="ghost"
          onClick={() => openDialog("resetState")}
          className="outlined-btn">
          Reset State
        </Button>
        <Button
          appearance="ghost"
          onClick={() => openDialog(user?.deactivated ? "activate" : "deactivate")}
          className="outlined-btn">
          {user?.deactivated ? "Activate" : "Deactivate"}
        </Button>
        <Button
          appearance="ghost"
          onClick={() => openDialog("delete")}
          className="outlined-btn red-outlined-btn">
          Delete
        </Button>
      </div>
      <CustomDialogIcon
        open={dialog}
        close={() => setDialog(false)}
        content={dialogContents[dialogContent]}
        isLoading={isLoading}
      />
    </div>
  );
}
