import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ChartHeader from "../charts/ChartHeader";

export function LeaderItem() {
  return (
    <Link
      to="/"
      className="leaderList__item page-block-pd">
      <img
        className="leaderList__img"
        src="https://www.foto-bern.ch/wp-content/uploads/2022/07/Portrait-357.-vorschau.jpg"
        alt="leader"
      />
      <p>Jaffar Rignour</p>
      <p>Africa</p>
      <p>89/100</p>
      <KeyboardArrowRightIcon style={{ color: "#475467" }} />
    </Link>
  );
}

export default function Leaderboard() {
  return (
    <div className="page-block pb-0">
      <div className="page-block-pd">
        <div className="flex items-center justify-between">
          <ChartHeader title="Leaderboard" />
          <Link
            className="page-block-link"
            to="/">
            View all
          </Link>
        </div>
        <p className="page-block-value"></p>
      </div>
      <div className="leaderList">
        <LeaderItem />
        <LeaderItem />
        <LeaderItem />
        <LeaderItem />
        <LeaderItem />
      </div>
    </div>
  );
}
