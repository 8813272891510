import { useState, useRef } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button } from "rsuite";

export default function AttachmentUpload({ handleDelete, attachmentsError, handleFile, handleChange }) {
  const [dragActive, setDragActive] = useState(false);
  const [preview, setPreview] = useState(null);
  const inputRef = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      if (validateFile(file)) {
        handleFile(file);
        createPreview(file);
      }
    }
  };

  const validateFile = (file) => {
    const validTypes = ["image/png", "image/jpeg", "image/gif", "image/svg+xml"];
    if (!validTypes.includes(file.type)) {
      alert("Unsupported file type. Please upload an image (PNG, JPG, GIF, SVG).");
      return false;
    }
    return true;
  };

  const createPreview = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file && validateFile(file)) {
      handleFile(file);
      createPreview(file);
    }
  };

  const clearPreview = () => {
    setPreview(null);
    if (inputRef.current) inputRef.current.value = "";
  };

  return (
    <div className="attachmentUpload">
      <div
        className={`attachmentUpload ${preview ? "hidden" : ""}`}
        onDragEnter={handleDrag}>
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={false}
          onChange={handleFileInputChange}
        />
        <label
          className={`attachmentUpload__label ${dragActive ? "drag-active" : ""}`}
          htmlFor="input-file-upload">
          <div>
            <div className="attachmentUpload__icon">
              <CloudUploadIcon />
            </div>
            <div className="attachmentUpload__text">
              <button
                data-e2e="upload-btn"
                type="button"
                onClick={() => inputRef.current.click()}>
                Click to upload
              </button>{" "}
              or drag and drop
            </div>
            <div className="attachmentUpload__text">SVG, PNG, JPG or GIF (max. 500x500)</div>
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}></div>
        )}
      </div>

      {attachmentsError && <p className="errorMessage">{attachmentsError}</p>}
      {preview && (
        <div className="attachmentPreview">
          <img
            src={preview}
            alt="Preview"
            className="attachmentPreview__img"
          />
          <div className="attachmentPreview__btns">
            <Button
              appearance="ghost"
              onClick={() => inputRef.current.click()}
              className="outlined-btn">
              Edit
            </Button>
            <Button
              appearance="ghost"
              onClick={clearPreview}
              className="outlined-btn red-outlined-btn">
              Remove
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
