import { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import CheckIcon from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";
import { usersTableHead } from "./helpers";
import UsersTableToolbar from "./UsersTableToolbar";
import { Skeleton } from "@mui/material";
import moment from "moment";

function EnhancedTableHead(props) {
  const { onSelectAllClick, orderBy, sortBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            indeterminateIcon={
              <div className="custom-checkbox">
                <RemoveIcon />
              </div>
            }
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            icon={<div className="custom-checkbox"></div>}
            checkedIcon={
              <div className="custom-checkbox">
                <CheckIcon />
              </div>
            }
            inputProps={{
              "aria-label": "select all users",
            }}
          />
        </TableCell>
        {usersTableHead.map((headCell) => (
          <TableCell
            key={headCell.id}
            width="20%"
            sortDirection={sortBy === headCell.id ? orderBy : false}>
            <TableSortLabel
              active={sortBy === headCell.id}
              direction={sortBy === headCell.id ? orderBy : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {sortBy === headCell.id ? (
                <Box
                  component="span"
                  sx={visuallyHidden}>
                  {orderBy === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function UsersTable({ isLoading, usersTotalCount, setLimit, limit, setPage, page, data, fetchData, setOrderBy, orderBy, sortBy, setSortBy }) {
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && orderBy === "asc";
    setOrderBy(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected([...data]);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.findIndex((obj) => obj._id === id);
    const currentUser = data.find((x) => x._id === id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, currentUser);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  function handleNavigate(row) {
    navigate(`/users/${row._id}`);
  }

  return (
    <>
      <UsersTableToolbar
        selected={selected}
        fetchData={fetchData}
        setSelected={setSelected}
      />
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            orderBy={orderBy}
            sortBy={sortBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={usersTableHead.length + 1}>
                  <div className="main-content-px">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              data.map((row, index) => {
                const isItemSelected = selected.some((obj) => obj._id === row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    onClick={() => handleNavigate(row)}
                    sx={{ cursor: "pointer" }}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(event) => handleClick(event, row._id)}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        icon={<div className="custom-checkbox"></div>}
                        checkedIcon={
                          <div className="custom-checkbox">
                            <CheckIcon />
                          </div>
                        }
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.userId}</TableCell>
                    <TableCell>{row.userDetails}</TableCell>
                    <TableCell>{moment(row.createdDate).format("DD/MM/YYYY HH:mm")}</TableCell>
                    <TableCell>{moment(row.updatedDate).format("DD/MM/YYYY HH:mm")}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={usersTotalCount}
        rowsPerPage={limit}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
