import { Button } from "rsuite";
import EditIcon from "@mui/icons-material/Edit";
import CustomDialog from "../libs/CustomDialog";
import { useState } from "react";
import ChangePasswordForm from "./ChangePasswordForm";

export default function SettingsPassword() {
  const [dialog, setDialog] = useState(false);

  return (
    <div className="page-block page-block-pd settings__password">
      <p className="title-sm text-greyTitle">Password</p>
      <p className="settings__password-title">Click below to update your password</p>
      <p className="settings__password-text">Last time updated : 12.09.2022</p>
      <Button
        onClick={() => setDialog(true)}
        appearance="primary"
        className="black-btn outlined-btn mt-24"
        startIcon={<EditIcon />}>
        Change Password
      </Button>
      <CustomDialog
        open={dialog}
        close={() => setDialog(false)}
        title="Change Password">
        <ChangePasswordForm close={() => setDialog(false)} />
      </CustomDialog>
    </div>
  );
}
