export const drillsData = [
  { id: 1, name: "Jane Smith", result: "Pending", attempts: 2, date: "2023-12-17" },
  { id: 2, name: "Emily White", result: "Pending", attempts: 3, date: "2024-06-24" },
  { id: 3, name: "Michael Green", result: "Pending", attempts: 3, date: "2024-05-01" },
  { id: 4, name: "Emily White", result: "Pass", attempts: 2, date: "2024-03-28" },
  { id: 5, name: "Emily White", result: "Pass", attempts: 3, date: "2024-08-10" },
  { id: 6, name: "Robert Brown", result: "Fail", attempts: 4, date: "2023-12-21" },
  { id: 7, name: "Michael Green", result: "Pending", attempts: 5, date: "2023-12-25" },
  { id: 8, name: "Jane Smith", result: "Fail", attempts: 5, date: "2024-05-23" },
  { id: 9, name: "John Doe", result: "Fail", attempts: 2, date: "2024-09-27" },
  { id: 10, name: "Emily White", result: "Fail", attempts: 4, date: "2024-06-12" },
  { id: 11, name: "Michael Green", result: "Pass", attempts: 3, date: "2023-11-25" },
  { id: 12, name: "John Doe", result: "Pending", attempts: 3, date: "2023-11-29" },
  { id: 13, name: "Emily White", result: "Pass", attempts: 4, date: "2024-09-21" },
  { id: 14, name: "Robert Brown", result: "Pass", attempts: 5, date: "2024-04-17" },
  { id: 15, name: "Jane Smith", result: "Pending", attempts: 4, date: "2024-06-02" },
  { id: 16, name: "Michael Green", result: "Fail", attempts: 3, date: "2024-03-08" },
  { id: 17, name: "Jane Smith", result: "Pending", attempts: 1, date: "2024-03-26" },
  { id: 18, name: "Michael Green", result: "Pass", attempts: 2, date: "2024-05-27" },
  { id: 19, name: "Emily White", result: "Pass", attempts: 2, date: "2023-11-21" },
  { id: 20, name: "Robert Brown", result: "Pending", attempts: 2, date: "2024-01-02" },
  { id: 21, name: "Jane Smith", result: "Pending", attempts: 1, date: "2023-11-25" },
  { id: 22, name: "John Doe", result: "Fail", attempts: 1, date: "2024-07-13" },
  { id: 23, name: "Robert Brown", result: "Fail", attempts: 4, date: "2024-04-04" },
  { id: 24, name: "Emily White", result: "Pass", attempts: 3, date: "2024-08-01" },
  { id: 25, name: "Robert Brown", result: "Pending", attempts: 2, date: "2024-10-23" },
  { id: 26, name: "Michael Green", result: "Pass", attempts: 2, date: "2024-01-01" },
  { id: 27, name: "Michael Green", result: "Pass", attempts: 1, date: "2023-11-21" },
  { id: 28, name: "Emily White", result: "Fail", attempts: 5, date: "2023-12-29" },
  { id: 29, name: "Jane Smith", result: "Pending", attempts: 4, date: "2024-01-09" },
  { id: 30, name: "Michael Green", result: "Pending", attempts: 3, date: "2024-09-04" },
  { id: 31, name: "Robert Brown", result: "Pending", attempts: 5, date: "2024-01-15" },
  { id: 32, name: "Jane Smith", result: "Fail", attempts: 2, date: "2024-06-21" },
  { id: 33, name: "Emily White", result: "Pending", attempts: 4, date: "2024-02-14" },
  { id: 34, name: "Michael Green", result: "Fail", attempts: 2, date: "2024-01-16" },
  { id: 35, name: "Robert Brown", result: "Pass", attempts: 2, date: "2023-12-15" },
  { id: 36, name: "Michael Green", result: "Pending", attempts: 1, date: "2024-08-03" },
  { id: 37, name: "Emily White", result: "Fail", attempts: 3, date: "2024-09-23" },
  { id: 38, name: "Jane Smith", result: "Pending", attempts: 4, date: "2024-07-22" },
  { id: 39, name: "Emily White", result: "Pass", attempts: 1, date: "2024-08-06" },
  { id: 40, name: "John Doe", result: "Pending", attempts: 5, date: "2024-05-19" },
  { id: 41, name: "Emily White", result: "Fail", attempts: 4, date: "2023-11-18" },
  { id: 42, name: "Michael Green", result: "Pass", attempts: 3, date: "2024-07-05" },
  { id: 43, name: "Jane Smith", result: "Fail", attempts: 2, date: "2023-11-25" },
  { id: 44, name: "Michael Green", result: "Fail", attempts: 3, date: "2024-06-18" },
  { id: 45, name: "Robert Brown", result: "Pass", attempts: 5, date: "2024-08-19" },
  { id: 46, name: "John Doe", result: "Pending", attempts: 1, date: "2023-11-17" },
  { id: 47, name: "Jane Smith", result: "Pass", attempts: 4, date: "2023-12-14" },
  { id: 48, name: "Michael Green", result: "Pending", attempts: 2, date: "2024-10-03" },
  { id: 49, name: "Emily White", result: "Pending", attempts: 5, date: "2024-09-10" },
  { id: 50, name: "John Doe", result: "Fail", attempts: 3, date: "2024-02-27" },
];

export const allUsersData = [
  { id: 1, fullName: "John Doe", level: "Expert", age: 28, country: "USA", gender: "Male", position: "Developer" },
  { id: 2, fullName: "Emily White", level: "Proficient", age: 30, country: "France", gender: "Female", position: "Analyst" },
  { id: 3, fullName: "Emily White", level: "Proficient", age: 30, country: "France", gender: "Female", position: "Analyst" },
  { id: 4, fullName: "Emily White", level: "Proficient", age: 30, country: "France", gender: "Female", position: "Analyst" },
  { id: 5, fullName: "Emily White", level: "Proficient", age: 30, country: "France", gender: "Female", position: "Analyst" },
  { id: 6, fullName: "Jane Smith", level: "Intermediate", age: 34, country: "Canada", gender: "Female", position: "Designer" },
  { id: 7, fullName: "Robert Brown", level: "Amateur", age: 45, country: "Germany", gender: "Male", position: "Manager" },
  { id: 8, fullName: "Emily White", level: "Proficient", age: 30, country: "France", gender: "Female", position: "Analyst" },
  { id: 9, fullName: "Emily White", level: "Proficient", age: 30, country: "France", gender: "Female", position: "Analyst" },
  { id: 10, fullName: "Michael Green", level: "Intermediate", age: 27, country: "Japan", gender: "Male", position: "Engineer" },
];

// const user = {
// 	id: "",
// 	deactivated: false, // what should we display in figma if user is deactivated and can we reactive him
// 	deleted: false, // what happens if user is deleted
//   name: "",
//   country: "",
//   email: "",
//   gender: "",
//   age: "",
//   height: "",
//   position: "",
//   strongFoot: "",
//   //are these skills fixed, or can be changed? how we decide if value is very food, average or bad? in ui it has an additional tag
//   skills: {
//     movement: "",
//     speed: "",
//     awareness: "",
//     ballControll: "",
//   },
//   rank: "" || 0, // string or number, I do not know exact value
//   avgScore: 0, // should be a number?
//   notes: [], // array of strings I guess?
//   drills: {
//     history: [
//       {
//         name: "",
//         results: "" || 0, // string or number? I do not know
//         attempts: 0,
//         date: "",
// 				type: "?", // should be confirmed
//         details: [], // should be confirmed what is in expand drill row
//       },
//     ],
// 		completedCount: 0, // total count of completed drills
// 		totalCount: 0, // total count of drills,
// 		goodCount: 0,
// 		badCount: 0
//   },
// 	// maybe last 6-7 users
// 	similarPlayers: [
// 		{
// 			userId: "",
// 			avatar: "",
// 			name: "",
// 			country: "",
// 			score: 0
// 		}
// 	],
// 	activity: [
// 		{
// 			id: "",
// 			name: "",
// 			date: "",
// 			completed: false || true,
// 			type: "?" // do we have type for activity item? Because in figma there is a select to filter by type
// 		}
// 	],
// 	sessions: {
// 		history: [
// 			{
// 				id: "",
// 				date: "",
// 				journey: [""] //array of strings
// 			}
// 		],
// 		onboarding: "?", // is this value the same like user, or for activity it has separate meaning
// 		avgTimeSpent: "5 min",
// 		avgPageCount: 0,
// 		useOverTime: "+5%"
// 	},
// 	coachChats: [
// 		{
// 			id: "",
// 			avatar: "",
// 			isCoach: true || false,
// 			name: "",
// 			message: "",
// 			date: ""
// 		}
// 	]
// };

// // * add coach charts search + date
// // * add notes for user
// // * reset password for user
// // * reset state for user
// // * deactivate api
// // + delete one user (we already have an api to delete users)
