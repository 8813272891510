import CallMadeIcon from "@mui/icons-material/CallMade";

export default function ChartHeaderValue({ percent }) {
  return (
    <span className="page-block-percent">
      <CallMadeIcon fontSize="small" />
      {percent}
    </span>
  );
}
