import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import AppRoutes from "./routes/routes";
import CustomSnackbar from "./components/libs/CustomSnackbar";
import { Provider } from "react-redux";
import store from "./store";

const theme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif",
  },
});

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <AppRoutes />
        </Router>
        <CustomSnackbar />
      </ThemeProvider>
    </Provider>
  );
}
