import PersonalDetailsBlock from "./PersonalDetailsBlock";
import PersonalDetailsRank from "./PersonalDetailsRank";
import PersonalDetailsNotes from "./PersonalDetailsNotes";

export default function PersonalDetails({ rank }) {
  return (
    <div className="userIdGroup grid-cols-3">
      <PersonalDetailsBlock />
      <PersonalDetailsRank rank={rank} />
      <PersonalDetailsNotes />
    </div>
  );
}
