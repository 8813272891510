import ReactApexChart from "react-apexcharts";
import { useState } from "react";

export default function DrillsChart({ totalData }) {
  const colors = totalData.map((x) => x.color);
  const [chartData] = useState({
    options: {
      chart: { fontFamily: "Inter, sans-serif" },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "May", "Apr", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      },
      colors: colors,
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Completed",
        data: [1211, 7948, 3984, 3476, 1629, 7698, 2947, 5656, 6607, 6329, 5436, 2655],
      },
      {
        name: "In Review",
        data: [4093, 6263, 3105, 1514, 2290, 5836, 1439, 2983, 2337, 1643, 7603, 3512],
      },
      {
        name: "Failed",
        data: [5339, 3716, 7082, 5655, 7271, 3200, 3195, 1667, 2119, 5118, 2813, 1574],
      },
      {
        name: "Rejected",
        data: [3194, 6168, 6225, 2474, 4992, 4330, 7400, 2816, 7809, 7251, 1808, 7616],
      },
    ],
  });

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="area"
      height={227}
    />
  );
}
