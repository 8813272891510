import ReactApexChart from "react-apexcharts";
import { useState } from "react";

export default function CoachChart({ totalData }) {
  const colors = totalData.map((x) => x.color);

  const [chartData] = useState({
    options: {
      chart: {
        stacked: false,
        fontFamily: "Inter, sans-serif",
      },
      stroke: {
        width: [0, 2, 0],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      colors: colors,
      labels: ["Jan 2023", "Feb 2023", "Mar 2023", "Apr 2023", "May 2023", "Jun 2023", "Jul 2023", "Aug 2023", "Sep 2023", "Oct 2023", "Nov 2023", "Dec 2023"],
    },
    series: [
      {
        name: "Total Sent",
        type: "column",
        data: [379031, 515360, 444974, 325183, 305999, 919308, 600583, 548676, 733726, 465504, 733726, 548676],
      },
      {
        name: "Reply",
        type: "line",
        data: [90967, 123686, 106794, 78044, 73440, 220634, 144140, 131682, 176094, 111721, 112965, 198179],
      },
      {
        name: "Errors",
        type: "column",
        data: [18193, 24737, 21359, 15609, 14688, 44127, 28828, 26336, 35219, 22344, 22593, 39636],
      },
    ],
  });

  return (
    <div className="coach-chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={400}
      />
    </div>
  );
}
