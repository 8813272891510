import CheckIcon from "@mui/icons-material/Check";

export default function SuccessIcon() {
  return (
    <div className="success-icon">
      <div className="success-icon-inner">
        <CheckIcon />
      </div>
    </div>
  );
}
