import { useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function TotalUsersChart() {
  const [chartData] = useState({
    options: {
      chart: { fontFamily: "Inter, sans-serif" },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "May", "Apr", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      },
      colors: ["#039855"],
      dataLabels: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Total users",
        data: [100, 203, 498, 2000, 2974, 4985, 3084, 4500, 5000, 6000, 6638, 8764],
      },
    ],
  });

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="bar"
      height={227}
    />
  );
}
