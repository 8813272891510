import Layout from "../components/Layout";
import TotalDrills from "../components/dashboard/TotalDrills";
import Leaderboard from "../components/dashboard/Leaderboard";
import TotalUsers from "../components/dashboard/TotalUsers";
import Location from "../components/dashboard/Location";
import SignUpStatus from "../components/dashboard/SignUpStatus";
import Coach from "../components/dashboard/Coach";
import PlayerRole from "../components/dashboard/PlayerRole";
import Distribution from "../components/dashboard/Distribution";

export default function DashboardPage() {
  return (
    <Layout bodyColor="#eef2f6">
      <div className="dashboard main-content-px">
        <div className="dashboard__grid">
          <TotalUsers />
          <Leaderboard />
        </div>
        <TotalDrills />
        <div className="dashboard__grid">
          <Location />
          <SignUpStatus />
        </div>
        <Coach />
        <div className="dashboard__grid">
          <PlayerRole />
          <Distribution />
        </div>
      </div>
    </Layout>
  );
}
