import CountryFlag from "../libs/CountryFlag";
import { useSelector } from "react-redux";

export default function PersonalDetailsBlock() {
  const user = useSelector((state) => state.user);

  return (
    <div className="userIdGroup__block border-right main-content-px">
      <p className="title-sm text-greyTitle">Personal details</p>
      <div className="personalDetails__list">
        <div className="personalDetails__item">
          <p>Full name</p>
          <p>
            <span>{user?.name}</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>*Country</p>
          <p>
            <CountryFlag country="US" />
            <span>Africa, Cape Town</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>Email</p>
          <p>
            <span className="text-linkColor">{user?.userId}</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>*Gender</p>
          <p>
            <span>Male</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>*Age</p>
          <p>
            <span>18 yr</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>*Date of birth</p>
          <p>
            <span>15/09/2021</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>*Height</p>
          <p>
            <span>170 cm (5’7)</span>
          </p>
        </div>

        <div className="personalDetails__item">
          <p>*Position</p>
          <p>
            <span>Central Defender</span>
          </p>
        </div>
        <div className="personalDetails__item">
          <p>*Strong foot</p>
          <p>
            <span>Both</span>
          </p>
        </div>
      </div>
    </div>
  );
}
