import Layout from "../components/Layout";
import UserIdLayout from "../components/users/UserIdLayout";
import { UsersStatsItem } from "./UsersPage";
import { DateRangePicker } from "rsuite";
import { useState } from "react";
import MultipleAutocomplete from "../components/libs/MultipleAutocomplete";

const typeOptions = [
  { label: "Amateur", value: "Amateur" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Proficient", value: "Proficient" },
  { label: "Expert", value: "Expert" },
];

export function HlightsItem() {
  return (
    <div className="hlights__item">
      <img
        src="/images/avatar.png"
        alt=""
      />
      <p className="font-medium">Drill name</p>
      <p className="text-greyTitle">18.09.2024</p>
    </div>
  );
}

export default function HighlightsPage() {
  const [typesSelected, setTypes] = useState([]);

  return (
    <Layout>
      <UserIdLayout>
        <div className="usersStats main-content-px grid-cols-3 py-15">
          <UsersStatsItem
            title="Total highlights"
            value={7}
          />
          <UsersStatsItem
            title="Good"
            value={65}
          />
          <UsersStatsItem
            title="Bad"
            value={3}
          />
        </div>
        <div className="filters-block main-content-px">
          <MultipleAutocomplete
            options={typeOptions}
            label="Type"
            onChange={(event, newValues) => setTypes(newValues)}
          />
          <div>
            <p className="filter-label">Date</p>
            <DateRangePicker size="lg" />
          </div>
        </div>
        <div className="main-content-px activity">
          <div className="hlights__list">
            <HlightsItem />
            <HlightsItem />
            <HlightsItem />
            <HlightsItem />
          </div>
        </div>
      </UserIdLayout>
    </Layout>
  );
}
