import { NavLink, useParams } from "react-router-dom";

export default function UserNavigation() {
  const { id } = useParams();
  return (
    <div className="userNav">
      <div className="userNav__row main-content-px">
        <NavLink
          to={`/users/${id}`}
          end>
          Overview
        </NavLink>
        <NavLink to={`/users/${id}/drill-history`}>Drill History</NavLink>
        <NavLink to={`/users/${id}/activity`}>Activity</NavLink>
        <NavLink to={`/users/${id}/sessions`}>Sessions</NavLink>
        <NavLink to={`/users/${id}/coach-chats`}>Coach Chats</NavLink>
        <NavLink to={`/users/${id}/highlights`}>Highlights</NavLink>
      </div>
    </div>
  );
}
