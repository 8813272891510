import Layout from "../components/Layout";
import UserIdLayout from "../components/users/UserIdLayout";
import { UsersStatsItem } from "./UsersPage";
import { DateRangePicker } from "rsuite";
import DrillsHistoryTable from "../components/users/DrillsHistoryTable";
import { useSelector } from "react-redux";

export default function DrillHistoryPage() {
  const user = useSelector((state) => state.user);
  return (
    <Layout>
      <UserIdLayout>
        <div className="usersStats main-content-px grid-cols-4 py-15">
          <UsersStatsItem
            title="Attempts"
            value={user?.userDetails?.drills?.attemptsCount}
          />
          <UsersStatsItem
            title="Avg. Result"
            value={user?.userDetails?.drills?.avgResult}
          />
          <UsersStatsItem
            title="Failed"
            value={user?.userDetails?.drills?.failedCount}
          />
          <UsersStatsItem
            title="Passed"
            value={user?.userDetails?.drills?.passedCount}
          />
        </div>
        <div className="filters-block main-content-px">
          <div>
            <p className="filter-label">Date</p>
            <DateRangePicker size="lg" />
          </div>
        </div>
        <DrillsHistoryTable />
      </UserIdLayout>
    </Layout>
  );
}
