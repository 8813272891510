import Layout from "../components/Layout";
import AgeRange from "../components/libs/AgeRange";
import MultipleAutocomplete from "../components/libs/MultipleAutocomplete";
import { useEffect, useState, useCallback } from "react";
import { Button } from "rsuite";
import UsersTable from "../components/users/UsersTable";
import { instance } from "../routes/axios";
import { InputGroup, Input } from "rsuite";
import useDebounce from "../helpers/useDebounce";
import AdminsTable from "../components/admins/AdminsTable";

export function UsersStatsItem({ title, value, children }) {
  return (
    <div className="usersStats__item">
      <p className="title-sm text-greyTitle">{title}</p>
      {!children && <div className="page-block-value flex items-center mb-0">{value}</div>}
      {children}
    </div>
  );
}

const levelOptions = [
  { label: "Amateur", value: "Amateur" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Proficient", value: "Proficient" },
  { label: "Expert", value: "Expert" },
];

const countryOptions = [
  { label: "United States", value: "United States" },
  { label: "Canada", value: "Canada" },
  { label: "Germany", value: "Germany" },
  { label: "France", value: "France" },
  { label: "Japan", value: "Japan" },
  { label: "Australia", value: "Australia" },
];

const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const positionOptions = [
  { label: "Position 1", value: "Position 1" },
  { label: "Position 2", value: "Position 2" },
];

export default function AdminsPage() {
  const [users, setUsers] = useState([]);
  const [usersTotalCount, setUsersTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [orderBy, setOrderBy] = useState("asc");
  const [sortBy, setSortBy] = useState("createdAt");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const handleLevelChange = (newValues) => setSelectedLevels(newValues);
  const handleCountryChange = (newValues) => setSelectedCountries(newValues);
  const handleGenderChange = (newValues) => setSelectedGenders(newValues);
  const handlePositionChange = (newValues) => setSelectedPositions(newValues);

  const fetchData = useCallback(
    async (search = "") => {
      setLoading(true);
      try {
        const res = await instance.post("/fetch-admins", { orderBy, sortBy, page, limit, search });
        setUsers(res.data.adminUsers);
        setUsersTotalCount(res.data.totalCount);
        setLoading(false);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [orderBy, sortBy, page, limit]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const debouncedFetchData = useDebounce(fetchData, 500);

  const handleSearchChange = (value) => {
    setSearch(value);
    debouncedFetchData(value);
  };

  return (
    <Layout>
      <div className="main-content-px">
        <p className="main-content-title">Admins</p>
        <div className="filters-block filters-block-users">
          <div>
            <p className="filter-label">Search</p>
            <InputGroup size="lg">
              <Input
                placeholder="Search admin by name, email..."
                onChange={(value) => handleSearchChange(value)}
              />
            </InputGroup>
          </div>
          {/* <MultipleAutocomplete
            options={levelOptions}
            label="Level"
            onChange={(event, newValues) => handleLevelChange(newValues)}
          />
          <MultipleAutocomplete
            options={countryOptions}
            label="Country"
            onChange={(event, newValues) => handleCountryChange(newValues)}
          />
          <MultipleAutocomplete
            options={genderOptions}
            label="Gender"
            onChange={(event, newValues) => handleGenderChange(newValues)}
          />
          <MultipleAutocomplete
            options={positionOptions}
            label="Position"
            onChange={(event, newValues) => handlePositionChange(newValues)}
          />
          <AgeRange />
          <Button
            appearance="ghost"
            className="outlined-btn mt-23">
            Reset
          </Button> */}
        </div>
      </div>
      <AdminsTable
        data={users}
        fetchData={fetchData}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        sortBy={sortBy}
        setSortBy={setSortBy}
        limit={limit}
        setLimit={setLimit}
        page={page}
        setPage={setPage}
        usersTotalCount={usersTotalCount}
        isLoading={isLoading}
      />
    </Layout>
  );
}
