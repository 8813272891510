import UserIdLayout from "../components/users/UserIdLayout";
import Layout from "../components/Layout";
import { DateRangePicker } from "rsuite";
import { Input, InputGroup } from "rsuite";
import { instance } from "../routes/axios";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatsList from "../components/users/ChatsList";
import { Skeleton } from "@mui/material";
import { showSnackbar } from "../store/snackbarSlice";
import useDebounce from "../helpers/useDebounce";

export default function CoachChatsPage() {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const fetchData = useCallback(
    async (search = "", range = []) => {
      if (!user?.userDetails?._id) return;
      setLoading(true);
      try {
        const res = await instance.post("/get-coach-chats", {
          userDetailsId: user.userDetails._id,
          search: search,
          startDate: range ? range[0] : null,
          endDate: range ? range[1] : null,
        });
        setData(res.data.chats);
        setTotalCount(res.data.totalCount);
      } catch (err) {
        console.error(err);
        dispatch(showSnackbar({ message: "Something went wrong", severity: "error" }));
      } finally {
        setLoading(false);
      }
    },
    [user, dispatch]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const debouncedFetchData = useDebounce(fetchData, 500);

  const handleSearchChange = (value) => {
    setSearchValue(value);
    debouncedFetchData(value, dateRange);
  };

  const handleDateChange = (range) => {
    setDateRange(range);
    fetchData(searchValue, range);
  };

  const cleanDateChange = () => {
    setDateRange([]);
    fetchData(searchValue, []);
  };

  return (
    <Layout>
      <UserIdLayout>
        <div className="filters-block chats__filters main-content-px">
          <div>
            <p className="filter-label">Search</p>
            <InputGroup
              size="lg"
              disabled={totalCount < 1}>
              <Input
                placeholder="Search messages..."
                onChange={(value) => handleSearchChange(value)}
              />
            </InputGroup>
          </div>
          <div>
            <p className="filter-label">Date</p>
            <DateRangePicker
              disabled={totalCount < 1}
              caretAs={null}
              size="lg"
              value={dateRange}
              onChange={handleDateChange}
              onClean={cleanDateChange}
            />
          </div>
        </div>
        <div className="activity main-content-px">
          {isLoading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <ChatsList
              data={data}
              searchValue={searchValue}
            />
          )}
        </div>
      </UserIdLayout>
    </Layout>
  );
}
